import React from "react"
import {
  SPACE_2XL,
  SPACE_M,
  SPACE_1XL,
  SPACE_XL,
  SPACE_L,
  SPACE_S,
  COLOR_GRAY_100,
  COLOR_SUCCESS,
} from "../styles/primitives"
import { getThemeByName } from "../styles/themes"
import { ANIMATION_EASING, ANIMATION_DURATION } from "../styles/animations"

import { createGlobalStyle, css } from "styled-components"
import { createTextStylesFromToken } from "./Text"
import {
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM,
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_MD,
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_LG,
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_XL,
  TEXT_TOKEN_COPY_BREAKPOINT_SM,
  TEXT_TOKEN_COPY_BREAKPOINT_MD,
  TEXT_TOKEN_COPY_BREAKPOINT_LG,
  TEXT_TOKEN_COPY_BREAKPOINT_XL,
  COLOR_BACKGROUND_PRIMARY,
  TEXT_TOKEN_BUTTON_BREAKPOINT_SM,
  TEXT_TOKEN_BUTTON_BREAKPOINT_XL,
  TEXT_TOKEN_BUTTON_BREAKPOINT_LG,
  TEXT_TOKEN_BUTTON_BREAKPOINT_MD,
  COLOR_TEXT_PRIMARY,
  COLOR_TEXT_INVERTED,
  COLOR_UI_BACKGROUND_PRIMARY,
  COLOR_BACKGROUND_INVERTED,
  breakpoints,
} from "../styles"
import { createLinkStyles } from "./links/linkStyles"
import { MAX_WIDTH } from "../styles/breakpoints"

export const IUBENDA_BANNER_Z_INDEX = 1000000

const buttonStyles = css`
  ${createTextStylesFromToken({
    SM: TEXT_TOKEN_BUTTON_BREAKPOINT_SM,
    MD: TEXT_TOKEN_BUTTON_BREAKPOINT_MD,
    LG: TEXT_TOKEN_BUTTON_BREAKPOINT_LG,
    XL: TEXT_TOKEN_BUTTON_BREAKPOINT_XL,
  })}
  appearance: none;
  outline: none;
  border-radius: ${SPACE_2XL}px;
  height: ${SPACE_1XL}px;
  padding: ${SPACE_M}px;
  border: 2px solid ${COLOR_BACKGROUND_PRIMARY};
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms,
    background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  &:hover {
    background-color: ${COLOR_BACKGROUND_PRIMARY};
    color: ${COLOR_TEXT_PRIMARY};
  }

  &.iubenda-cs-btn-primary:not(.iubenda-cs-reject-btn) {
    background-color: ${COLOR_BACKGROUND_PRIMARY};
    color: ${COLOR_TEXT_PRIMARY};

    &:hover {
      background-color: transparent;
      color: ${COLOR_TEXT_INVERTED};
    }
  }
`

const GlobalIubendaBannerStyles = createGlobalStyle`
#iubenda-cs-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 32px;
  position: fixed;
  z-index: ${IUBENDA_BANNER_Z_INDEX};
  bottom: ${SPACE_XL}px;
  left: calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px);
  right: calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px);
  height: fit-content;

  ${breakpoints({
    SM: css`
      top: 0;
      left: 0;
      right 0;
      border-radius: 0;

      .iubenda-cs-opt-group {
        flex-wrap: wrap;

        .iubenda-cs-opt-group-consent {
          width: 100%;
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: minmax(min-content, 1fr);
          grid-column-gap: ${SPACE_M}px;
          margin-top: ${SPACE_M}px;

          button {
            margin: 0;
          }
        }
        
        .iubenda-cs-opt-group-custom {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
  `,
    MD: css`
      top: 0;
      left: 0;
      right 0;
      border-radius: 0;
  `,
  })};

  background: #2E2D2D;
  border-radius: 16px;

  .iubenda-cs-opt-group,
  .iubenda-cs-opt-group-consent {
    display: flex;
    align-items:center;

    button + button {
      margin-left: ${SPACE_S}px;
    }
  }

  .iubenda-cs-opt-group-custom {
      margin-right: auto;
    }

  .iubenda-cs-close-btn {
    display: none;
  }

  a {
    transition: color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

    ${createLinkStyles(COLOR_TEXT_INVERTED, COLOR_GRAY_100, true)};
  }

  button {
    ${buttonStyles};
  }

  #iubenda-cs-title {
    ${createTextStylesFromToken({
      SM: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM,
      MD: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_MD,
      LG: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_LG,
      XL: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_XL,
    })};
    margin-bottom: ${SPACE_L}px;
  }

  #iubenda-cs-paragraph {
    ${createTextStylesFromToken({
      SM: TEXT_TOKEN_COPY_BREAKPOINT_SM,
      MD: TEXT_TOKEN_COPY_BREAKPOINT_MD,
      LG: TEXT_TOKEN_COPY_BREAKPOINT_LG,
      XL: TEXT_TOKEN_COPY_BREAKPOINT_XL,
    })}
    margin-bottom: ${SPACE_XL}px;
  }
}`

const GlobalIubendaModalStyles = createGlobalStyle`
  #iubenda-iframe-content {
    &&&&& {
      button:not(.open-cp):not(.purposes-item-title-btn) {
        transition: background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms !important;
        background-color: ${COLOR_UI_BACKGROUND_PRIMARY} !important;
        color: ${COLOR_TEXT_INVERTED} !important;

        &:hover {
          opacity: 1 !important;
          background-color: ${COLOR_BACKGROUND_INVERTED} !important;
        }

        &.purposes-btn {
          background-image: none !important;
          padding-left: 21px !important;
        }
      }

      .purposes-checkbox input:checked {
        background-color: ${COLOR_SUCCESS} !important;
      }

      .purposes-btn-back {
        transition: background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms, color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms !important;
        border-radius: ${SPACE_2XL}px !important;
        border: 2px solid ${COLOR_BACKGROUND_INVERTED} !important;
        color: ${COLOR_TEXT_PRIMARY} !important;

        &:hover {
          opacity: 1 !important;
          background-color: ${COLOR_BACKGROUND_INVERTED} !important;
          color: ${COLOR_TEXT_INVERTED} !important;
        }
      }

      #iubFooterBtnIab {
        text-decoration: ${({ theme }) =>
          theme.links.underline ? "underline" : "none"} !important;
        color: ${({ theme }) => theme.links.defaultColor} !important;
        cursor: pointer !important;
        transition: color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms !important;

        &:hover,
        &:focus,
        &:active {
          color: ${({ theme }) => theme.links.activeColor} !important;
        }
      }
    }
  }
`

export function GlobalIubendaStyles() {
  return (
    <>
      <GlobalIubendaBannerStyles theme={getThemeByName("dark")} />
      <GlobalIubendaModalStyles theme={getThemeByName("stone")} />
    </>
  )
}
