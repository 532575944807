import React, { forwardRef } from "react"
import styled, { css } from "styled-components"
import { getThemeByName } from "../styles"
import { breakpoints } from "../styles/helpers"
import { FONT_SIZE_L } from "../styles/primitives"
import {
  TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_SM,
  TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_MD,
  TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_LG,
  TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_XL,
  TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_SM,
  TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_MD,
  TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_LG,
  TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_XL,
  TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_SM,
  TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
  TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_LG,
  TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_XL,
  TEXT_TOKEN_META_BREAKPOINT_SM,
  TEXT_TOKEN_META_BREAKPOINT_MD,
  TEXT_TOKEN_META_BREAKPOINT_LG,
  TEXT_TOKEN_META_BREAKPOINT_XL,
  TEXT_TOKEN_BUTTON_BREAKPOINT_SM,
  TEXT_TOKEN_BUTTON_BREAKPOINT_MD,
  TEXT_TOKEN_BUTTON_BREAKPOINT_LG,
  TEXT_TOKEN_BUTTON_BREAKPOINT_XL,
  TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_SM,
  TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_MD,
  TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_LG,
  TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_XL,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_LG,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_SM,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_XL,
  TEXT_TOKEN_COPY_BREAKPOINT_SM,
  TEXT_TOKEN_COPY_BREAKPOINT_MD,
  TEXT_TOKEN_COPY_BREAKPOINT_LG,
  TEXT_TOKEN_COPY_BREAKPOINT_XL,
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM,
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_MD,
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_LG,
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_XL,
  TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_SM,
  TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_MD,
  TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_LG,
  TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_XL,
  TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_SM,
  TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_MD,
  TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_LG,
  TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_XL,
} from "../styles/tokens"

export function textConfigToCSS(config) {
  return css`
    font-size: ${config.fontSize}rem;
    line-height: ${config.lineHeight}em;
    letter-spacing: ${config.letterSpacing}em;
    font-weight: ${config.fontWeight};
  `
}

function textConfigSetToCSS(configSet) {
  return breakpoints({
    SM: configSet.SM && textConfigToCSS(configSet.SM),
    MD: configSet.MD && textConfigToCSS(configSet.MD),
    LG: configSet.LG && textConfigToCSS(configSet.LG),
    XL: configSet.XL && textConfigToCSS(configSet.XL),
  })
}

const standardStyles = css`
  color: ${({ theme }) => theme.textColor};
`

const headlineStyles = css`
  ${standardStyles};
`

export const heroTextStyles = css`
  ${headlineStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_SM,
    MD: TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_MD,
    LG: TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_LG,
    XL: TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_XL,
  })};
`

const StyledHeroH1 = styled.h1`
  ${heroTextStyles}
`

const StyledHero = styled.h2`
  ${heroTextStyles}
`

export const SHeadlinePrimaryText = styled.h2`
  ${headlineStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_SM,
    MD: TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_MD,
    LG: TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_LG,
    XL: TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_XL,
  })};
`

export const SHeadlineSecondaryText = styled.h3`
  ${headlineStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_SM,
    MD: TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_MD,
    LG: TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_LG,
    XL: TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_XL,
  })};
`

export const SHeadlineTertiaryText = styled.h4`
  ${headlineStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_SM,
    MD: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
    LG: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_LG,
    XL: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_XL,
  })};
`

export const ShopBoxHeadlineText = styled.h4`
  ${headlineStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_SM,
    MD: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
    LG: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
    XL: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
  })};
`

export const SHeadlineLevel5Text = styled.h5`
  ${headlineStyles};
`
export const SHeadlineLevel6Text = styled.h6`
  ${headlineStyles};
`

export const MetaText = styled.span`
  ${standardStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_META_BREAKPOINT_SM,
    MD: TEXT_TOKEN_META_BREAKPOINT_MD,
    LG: TEXT_TOKEN_META_BREAKPOINT_LG,
    XL: TEXT_TOKEN_META_BREAKPOINT_XL,
  })};
`

export const ButtonText = styled.span`
  ${standardStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_BUTTON_BREAKPOINT_SM,
    MD: TEXT_TOKEN_BUTTON_BREAKPOINT_MD,
    LG: TEXT_TOKEN_BUTTON_BREAKPOINT_LG,
    XL: TEXT_TOKEN_BUTTON_BREAKPOINT_XL,
  })};
`

export const SubHeadlineText = styled.div`
  ${standardStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_SM,
    MD: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
    LG: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_LG,
    XL: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_XL,
  })};
`

export const SubHeadlineParagraphText = styled.div`
  ${standardStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_SM,
    MD: TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_MD,
    LG: TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_LG,
    XL: TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_XL,
  })};
`

export const CopyText = styled.div`
  ${standardStyles};

  margin-top: 0;
  margin-bottom: 0;

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_COPY_BREAKPOINT_SM,
    MD: TEXT_TOKEN_COPY_BREAKPOINT_MD,
    LG: TEXT_TOKEN_COPY_BREAKPOINT_LG,
    XL: TEXT_TOKEN_COPY_BREAKPOINT_XL,
  })};
`

export const CopyStrongText = styled.div`
  ${standardStyles};

  margin-top: 0;
  margin-bottom: 0;

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM,
    MD: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_MD,
    LG: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_LG,
    XL: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_XL,
  })};
`

export const ShopPriceText = styled.div`
  ${standardStyles};

  ${textConfigSetToCSS({
    SM: TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_SM,
    MD: TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_MD,
    LG: TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_LG,
    XL: TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_XL,
  })};
`

export const CopyStrongSmallText = styled(CopyStrongText)`
  ${breakpoints({
    XL: css`
      font-size: ${FONT_SIZE_L}rem;
    `,
  })};
`

export const Text = forwardRef(({ type, ...otherProps }, ref) => {
  const HComponent = {
    heroH1: StyledHeroH1,
    hero: StyledHero,
    primary: SHeadlinePrimaryText,
    secondary: SHeadlineSecondaryText,
    tertiary: SHeadlineTertiaryText,
    h5: SHeadlineLevel5Text,
    h6: SHeadlineLevel6Text,
    meta: MetaText,
    button: ButtonText,
    subHeadline: SubHeadlineText,
    subHeadlineParagraph: SubHeadlineParagraphText,
    copy: CopyText,
    copyStrong: CopyStrongText,
    copyStrongSmall: CopyStrongSmallText,
    shopBoxHeadline: ShopBoxHeadlineText,
  }[type]

  if (!HComponent) {
    throw new Error(`Unknown text type ${type}`)
  }

  return <HComponent ref={ref} {...otherProps} />
})

export function createTextStylesFromToken(token, customThemeName) {
  return css`
    color: ${({ theme }) =>
      (customThemeName ? getThemeByName(customThemeName) : theme).textColor};

    ${textConfigSetToCSS({
      SM: token.SM || null,
      MD: token.MD ? { ...token.SM, ...token.MD } : null,
      LG: token.LG ? { ...token.MD, ...token.LG } : null,
      XL: token.XL ? { ...token.LG, ...token.XL } : null,
    })};
  `
}
