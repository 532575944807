import { css } from "styled-components"
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
} from "./breakpoints"
import { useMediaQueries } from "@react-hook/media-query"

const MEDIA_QUERY_SM = `(max-width: ${BREAKPOINT_SM}px)`
const MEDIA_QUERY_MD = `(min-width: ${
  BREAKPOINT_SM + 1
}px) and (max-width: ${BREAKPOINT_MD}px)`
const MEDIA_QUERY_LG = `(min-width: ${
  BREAKPOINT_MD + 1
}px) and (max-width: ${BREAKPOINT_LG}px)`
const MEDIA_QUERY_XL = `(min-width: ${
  BREAKPOINT_LG + 1
}px) and (max-width: ${BREAKPOINT_XL}px)`
const MEDIA_QUERY_MAX = `(min-width: ${BREAKPOINT_XL + 1}px)`

export function breakpoints({ SM, MD, LG, XL, MAX }) {
  return css`
    ${SM &&
    css`
      @media ${MEDIA_QUERY_SM} {
        ${SM}
      }
    `}
    ${MD &&
    css`
      @media ${MEDIA_QUERY_MD} {
        ${MD}
      }
    `}
    ${LG &&
    css`
      @media ${MEDIA_QUERY_LG} {
        ${LG}
      }
    `}
    ${XL &&
    css`
      @media ${MEDIA_QUERY_XL} {
        ${XL}
      }
    `}
    ${(MAX || XL) &&
    css`
      @media ${MEDIA_QUERY_MAX} {
        ${MAX || XL}
      }
    `}
  `
}

export function useScreenSizeName() {
  const { matches } = useMediaQueries({
    SM: MEDIA_QUERY_SM,
    MD: MEDIA_QUERY_MD,
    LG: MEDIA_QUERY_LG,
    XL: MEDIA_QUERY_XL,
  })

  if (matches.SM) {
    return "SM"
  }
  if (matches.MD) {
    return "MD"
  }
  if (matches.LG) {
    return "LG"
  }
  return "XL"
}
