/**
 * This allows us to use context and providers in Gatsby
 * both during runtime and build time.
 */
const React = require("react")
const GlobalStyles = require("./components/GlobalStyles")
const { LocaleProvider } = require("./locale")

function WrapWithProvider({ element, props }) {
  const { language = "en", country = "de" } = props.pageContext

  return (
    <LocaleProvider language={language} country={country}>
      <GlobalStyles />
      {element}
    </LocaleProvider>
  )
}

module.exports = WrapWithProvider
