import { css } from "styled-components"

export const linkStyles = css`
  ${({ theme }) =>
    createLinkStyles(
      theme.links.defaultColor,
      theme.links.activeColor,
      theme.links.underline
    )}
`

export function createLinkStyles(defaultColor, activeColor, underline) {
  return css`
    text-decoration: ${underline ? "underline" : "none"};
    color: ${defaultColor};
    cursor: pointer;

    .colored-fg-stroke {
      stroke: ${defaultColor};
    }
    .colored-fg-fill {
      fill: ${defaultColor};
    }

    &:hover,
    &:focus,
    &:active {
      color: ${activeColor};

      .colored-fg-stroke {
        stroke: ${activeColor};
      }
      .colored-fg-fill {
        fill: ${activeColor};
      }
    }
  `
}
