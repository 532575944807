import { COLOR_BLUE, COLOR_CORAL, COLOR_GREEN } from "./primitives"

export const modelColorsBySKUColorId = {
  MPNK: {
    id: "MPNK",
    name: "Matte Coral",
    color: "#F7726C",
    backgroundColor: COLOR_BLUE,
    changeModelColorMessage: {
      action: "loadMaterialSettings",
      materialId: 10,
      settingsId: 4,
    },
  },
  GBLU: {
    id: "GBLU",
    name: "Glossy Blue",
    color: "#293852",
    backgroundColor: COLOR_CORAL,
    changeModelColorMessage: {
      action: "loadMaterialSettings",
      materialId: 10,
      settingsId: 2,
    },
  },
  GRED: {
    id: "GRED",
    name: "Glossy Red",
    color: "#C53D2E",
    backgroundColor: COLOR_GREEN,
    changeModelColorMessage: {
      action: "loadMaterialSettings",
      materialId: 10,
      settingsId: 3,
    },
  },
  MGRN: {
    id: "MGRN",
    name: "Matte Pine",
    color: "#285545",
    backgroundColor: COLOR_BLUE,
    changeModelColorMessage: {
      action: "loadMaterialSettings",
      materialId: 10,
      settingsId: 5,
    },
  },
  MWHT: {
    id: "MWHT",
    name: "Matte White",
    color: "#F5F5F5",
    backgroundColor: COLOR_CORAL,
    changeModelColorMessage: {
      action: "loadMaterialSettings",
      materialId: 10,
      settingsId: 7,
    },
  },
  MBGE: {
    id: "MBGE",
    name: "Matte Stone",
    color: "#D0D2CE",
    backgroundColor: COLOR_BLUE,
    changeModelColorMessage: {
      action: "loadMaterialSettings",
      materialId: 10,
      settingsId: 6,
    },
  },
  MBLK: {
    id: "MBLK",
    name: "Matte Black",
    color: "#141414",
    backgroundColor: COLOR_CORAL,
    changeModelColorMessage: {
      action: "loadMaterialSettings",
      materialId: 10,
      settingsId: 1,
    },
  },
}
