// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-cms-page-js": () => import("./../../../src/templates/cmsPage.js" /* webpackChunkName: "component---src-templates-cms-page-js" */),
  "component---src-templates-order-status-js": () => import("./../../../src/templates/orderStatus.js" /* webpackChunkName: "component---src-templates-order-status-js" */),
  "component---src-templates-press-release-js": () => import("./../../../src/templates/pressRelease.js" /* webpackChunkName: "component---src-templates-press-release-js" */)
}

