import {
  FONT_SIZE_7XL,
  LINE_HEIGHT_TIGHT,
  LETTER_SPACING_TIGHTER,
  FONT_SIZE_10XL,
  FONT_SIZE_12XL,
  FONT_SIZE_5XL,
  FONT_SIZE_6XL,
  FONT_SIZE_8XL,
  FONT_SIZE_3XL,
  LINE_HEIGHT_SNUG,
  LETTER_SPACING_TIGHT,
  FONT_SIZE_4XL,
  FONT_SIZE_1XL,
  FONT_SIZE_2XL,
  FONT_SIZE_BASE,
  LETTER_SPACING_NORMAL,
  LINE_HEIGHT_LOOSE,
  FONT_SIZE_L,
  FONT_SIZE_XS,
  LINE_HEIGHT_NORMAL,
  LETTER_SPACING_WIDE,
  FONT_SIZE_SM,
  LETTER_SPACING_WIDER,
  FONT_SIZE_XL,
} from "../primitives"

export const TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 600,
  fontSize: FONT_SIZE_7XL,
  lineHeight: LINE_HEIGHT_TIGHT,
  letterSpacing: LETTER_SPACING_TIGHTER,
}
export const TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_MD = {
  ...TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_SM,
  fontSize: FONT_SIZE_10XL,
}
export const TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_LG = {
  ...TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_MD,
  fontSize: FONT_SIZE_12XL,
}
export const TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_XL = {
  ...TEXT_TOKEN_HEADLINE_HERO_BREAKPOINT_LG,
}

export const TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 600,
  fontSize: FONT_SIZE_5XL,
  lineHeight: LINE_HEIGHT_TIGHT,
  letterSpacing: LETTER_SPACING_TIGHTER,
}
export const TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_MD = {
  ...TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_SM,
  fontSize: FONT_SIZE_6XL,
}
export const TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_LG = {
  ...TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_MD,
  fontSize: FONT_SIZE_8XL,
}
export const TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_XL = {
  ...TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_LG,
}

export const TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 600,
  fontSize: FONT_SIZE_3XL,
  lineHeight: LINE_HEIGHT_SNUG,
  letterSpacing: LETTER_SPACING_TIGHT,
}
export const TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_MD = {
  ...TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_SM,
  fontSize: FONT_SIZE_4XL,
}
export const TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_LG = {
  ...TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_MD,
  fontSize: FONT_SIZE_5XL,
}
export const TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_XL = {
  ...TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_LG,
}

export const TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 600,
  fontSize: FONT_SIZE_XL,
  lineHeight: LINE_HEIGHT_SNUG,
  letterSpacing: LETTER_SPACING_TIGHT,
}
export const TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD = {
  ...TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_SM,
  fontSize: FONT_SIZE_1XL,
}
export const TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_LG = {
  ...TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
}
export const TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_XL = {
  ...TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_LG,
  fontSize: FONT_SIZE_2XL,
}

export const TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 400,
  fontSize: FONT_SIZE_XL,
  lineHeight: LINE_HEIGHT_SNUG,
  letterSpacing: LETTER_SPACING_TIGHT,
}
export const TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD = {
  ...TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_SM,
  fontSize: FONT_SIZE_1XL,
}
export const TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_LG = {
  ...TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
  fontSize: FONT_SIZE_2XL,
}
export const TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_XL = {
  ...TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_LG,
}

export const TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 600,
  fontSize: FONT_SIZE_BASE,
  lineHeight: LINE_HEIGHT_NORMAL,
  letterSpacing: LETTER_SPACING_NORMAL,
}
export const TEXT_TOKEN_COPY_STRONG_BREAKPOINT_MD = {
  ...TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM,
}
export const TEXT_TOKEN_COPY_STRONG_BREAKPOINT_LG = {
  ...TEXT_TOKEN_COPY_STRONG_BREAKPOINT_MD,
  fontSize: FONT_SIZE_L,
  lineHeight: LINE_HEIGHT_LOOSE,
}
export const TEXT_TOKEN_COPY_STRONG_BREAKPOINT_XL = {
  ...TEXT_TOKEN_COPY_STRONG_BREAKPOINT_LG,
  fontWeight: 600,
  fontSize: FONT_SIZE_XL,
}

export const TEXT_TOKEN_META_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 400,
  fontSize: FONT_SIZE_XS,
  lineHeight: LINE_HEIGHT_NORMAL,
  letterSpacing: LETTER_SPACING_WIDE,
}
export const TEXT_TOKEN_META_BREAKPOINT_MD = {
  ...TEXT_TOKEN_META_BREAKPOINT_SM,
}
export const TEXT_TOKEN_META_BREAKPOINT_LG = {
  ...TEXT_TOKEN_META_BREAKPOINT_MD,
  fontSize: FONT_SIZE_SM,
}
export const TEXT_TOKEN_META_BREAKPOINT_XL = {
  ...TEXT_TOKEN_META_BREAKPOINT_LG,
  lineHeight: LINE_HEIGHT_SNUG,
}

export const TEXT_TOKEN_COPY_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 400,
  fontSize: FONT_SIZE_BASE,
  lineHeight: LINE_HEIGHT_LOOSE,
  letterSpacing: LETTER_SPACING_NORMAL,
}
export const TEXT_TOKEN_COPY_BREAKPOINT_MD = {
  ...TEXT_TOKEN_COPY_BREAKPOINT_SM,
}
export const TEXT_TOKEN_COPY_BREAKPOINT_LG = {
  ...TEXT_TOKEN_COPY_BREAKPOINT_MD,
  fontSize: FONT_SIZE_L,
}
export const TEXT_TOKEN_COPY_BREAKPOINT_XL = {
  ...TEXT_TOKEN_COPY_BREAKPOINT_LG,
  fontSize: FONT_SIZE_XL,
}

export const TEXT_TOKEN_BUTTON_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 600,
  fontSize: FONT_SIZE_BASE,
  lineHeight: LINE_HEIGHT_TIGHT,
  letterSpacing: LETTER_SPACING_WIDER,
}
export const TEXT_TOKEN_BUTTON_BREAKPOINT_MD = {
  ...TEXT_TOKEN_BUTTON_BREAKPOINT_SM,
}
export const TEXT_TOKEN_BUTTON_BREAKPOINT_LG = {
  ...TEXT_TOKEN_BUTTON_BREAKPOINT_MD,
}
export const TEXT_TOKEN_BUTTON_BREAKPOINT_XL = {
  ...TEXT_TOKEN_BUTTON_BREAKPOINT_LG,
  fontSize: FONT_SIZE_L,
}

export const TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_SM = {
  fontFamily: "sans",
  fontWeight: 600,
  fontSize: FONT_SIZE_1XL,
  lineHeight: LINE_HEIGHT_TIGHT,
  letterSpacing: LETTER_SPACING_WIDE,
}
export const TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_MD = {
  ...TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_SM,
  fontSize: FONT_SIZE_2XL,
}
export const TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_LG = {
  ...TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_MD,
}
export const TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_XL = {
  ...TEXT_TOKEN_SHOP_PRICE_BREAKPOINT_LG,
}

// This one is not an official token but quite heavily used in the designs
export const TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_SM = {
  ...TEXT_TOKEN_COPY_BREAKPOINT_LG,
  lineHeight: 1.5,
}
export const TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_MD = {
  ...TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_SM,
  fontSize: 2,
  lineHeight: 1.4,
  letterSpacing: LETTER_SPACING_TIGHTER,
}
export const TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_LG = {
  ...TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_MD,
  fontSize: 2.5,
  lineHeight: 1.4,
  letterSpacing: LETTER_SPACING_TIGHTER,
}
export const TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_XL = {
  ...TEXT_TOKEN_SUB_HEADLINE_PARAGRAPH_BREAKPOINT_LG,
}
