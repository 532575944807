import React, { createContext, memo, useContext, useMemo } from "react"
import { useLocation } from "@reach/router"
import {
  ThemeContext,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components"
import {
  COLOR_CORAL,
  COLOR_GRAY_100,
  COLOR_GRAY_800,
  COLOR_GRAY_900,
  COLOR_GREEN,
  COLOR_STONE,
} from "./primitives"
import { COLOR_INTERACTIVE_LINK, COLOR_TEXT_INVERTED } from "./tokens"
import { modelColorsBySKUColorId } from "./scooterModelColors"

const themesByName = {
  stone: {
    backgroundColor: COLOR_STONE,
    iconColor: COLOR_INTERACTIVE_LINK,
    textColor: COLOR_GRAY_900,
    hoverTextColor: COLOR_CORAL,
    invertedButtons: false,
    links: {
      defaultColor: COLOR_INTERACTIVE_LINK,
      activeColor: COLOR_CORAL,
      underline: false,
    },
  },
  green: {
    backgroundColor: COLOR_GREEN,
    iconColor: COLOR_STONE,
    textColor: COLOR_STONE,
    hoverTextColor: COLOR_CORAL,
    invertedButtons: false,
    links: {
      defaultColor: COLOR_STONE,
      activeColor: COLOR_GRAY_100,
      underline: true,
    },
  },
  coral: {
    backgroundColor: COLOR_CORAL,
    iconColor: COLOR_STONE,
    textColor: COLOR_STONE,
    hoverTextColor: COLOR_GREEN,
    invertedButtons: true,
    links: {
      defaultColor: COLOR_STONE,
      activeColor: COLOR_GRAY_100,
      underline: true,
    },
  },
  dark: {
    backgroundColor: COLOR_GRAY_800,
    iconColor: COLOR_TEXT_INVERTED,
    textColor: COLOR_TEXT_INVERTED,
    hoverTextColor: COLOR_CORAL,
    invertedButtons: true,
    links: {
      defaultColor: COLOR_INTERACTIVE_LINK,
      activeColor: COLOR_CORAL,
      underline: false,
    },
  },
}

for (const themeName in themesByName) {
  themesByName[themeName].themeName = themeName
}

export const THEME_NAMES = Object.keys(themesByName)

const MemoizedInnerThemeProvider = memo(function InnerThemeProvider({
  themeName = "stone",
  pageThemeColor,
  children,
}) {
  const combinedTheme = useMemo(
    () => ({
      ...themesByName[themeName],
      pageThemeColor,
    }),
    [pageThemeColor, themeName]
  )

  return (
    <StyledComponentsThemeProvider theme={combinedTheme}>
      {children}
    </StyledComponentsThemeProvider>
  )
})

const pageThemeColorContext = createContext(null)

export function PageThemeColorProvider({ colorId, children }) {
  return (
    <pageThemeColorContext.Provider
      value={modelColorsBySKUColorId[colorId] || null}
    >
      {children}
    </pageThemeColorContext.Provider>
  )
}

export function usePageThemeColor() {
  return useContext(pageThemeColorContext)
}

export function ThemeProvider(props) {
  const pageThemeColor = usePageThemeColor()

  return (
    <MemoizedInnerThemeProvider pageThemeColor={pageThemeColor} {...props} />
  )
}

export function useTheme() {
  const theme = useContext(ThemeContext) ?? themesByName.stone

  return { theme }
}

export function getThemeByName(themeName) {
  return themesByName[themeName]
}
