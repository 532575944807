export const SPACE_XS = 4
export const SPACE_S = 8
export const SPACE_M = 16
export const SPACE_L = 24
export const SPACE_XL = 32
export const SPACE_1XL = 48
export const SPACE_2XL = 64
export const SPACE_3XL = 96
export const SPACE_4XL = 128

export const SPACE_TOKEN_STACK_XS = SPACE_XS
export const SPACE_TOKEN_STACK_S = SPACE_S
export const SPACE_TOKEN_STACK_M = SPACE_M
export const SPACE_TOKEN_STACK_L = SPACE_L
export const SPACE_TOKEN_STACK_XL = SPACE_XL
export const SPACE_TOKEN_STACK_1XL = SPACE_1XL
export const SPACE_TOKEN_STACK_2XL = SPACE_2XL
export const SPACE_TOKEN_STACK_3XL = SPACE_3XL
export const SPACE_TOKEN_STACK_4XL = SPACE_4XL

export const SPACE_TOKEN_INLINE_XS = SPACE_XS
export const SPACE_TOKEN_INLINE_S = SPACE_S
export const SPACE_TOKEN_INLINE_M = SPACE_M
export const SPACE_TOKEN_INLINE_L = SPACE_L
export const SPACE_TOKEN_INLINE_XL = SPACE_XL
export const SPACE_TOKEN_INLINE_1XL = SPACE_1XL
export const SPACE_TOKEN_INLINE_2XL = SPACE_2XL
export const SPACE_TOKEN_INLINE_3XL = SPACE_3XL
export const SPACE_TOKEN_INLINE_4XL = SPACE_4XL

export const FONT_SIZE_XS = 0.79
export const FONT_SIZE_SM = 0.889
export const FONT_SIZE_BASE = 1
export const FONT_SIZE_L = 1.125
export const FONT_SIZE_XL = 1.266
export const FONT_SIZE_1XL = 1.424
export const FONT_SIZE_2XL = 1.602
export const FONT_SIZE_3XL = 1.802
export const FONT_SIZE_4XL = 2.027
export const FONT_SIZE_5XL = 2.281
export const FONT_SIZE_6XL = 2.566
export const FONT_SIZE_7XL = 2.887
export const FONT_SIZE_8XL = 3.247
export const FONT_SIZE_9XL = 3.653
export const FONT_SIZE_10XL = 4.11
export const FONT_SIZE_11XL = 4.624
export const FONT_SIZE_12XL = 5.202
export const FONT_SIZE_13XL = 5.852

export const LINE_HEIGHT_NONE = 1
export const LINE_HEIGHT_TIGHT = 1.1
export const LINE_HEIGHT_SNUG = 1.3
export const LINE_HEIGHT_NORMAL = 1.4
export const LINE_HEIGHT_LOOSE = 1.5

export const LETTER_SPACING_TIGHTER = -0.02
export const LETTER_SPACING_TIGHT = -0.01
export const LETTER_SPACING_NORMAL = 0
export const LETTER_SPACING_WIDE = 0.01
export const LETTER_SPACING_WIDER = 0.02

export const COLOR_STONE = "#F7F3F0"
export const COLOR_CORAL = "#FF6A62"
export const COLOR_BLUE = "#0395FF"
export const COLOR_GREEN = "#245544"
export const COLOR_DARK_CORAL = "#FF554C"
export const COLOR_SUCCESS = "#02A40B"
export const COLOR_ERROR = "#CD2026"
export const COLOR_GRAY_100 = "#FFFEFC"
export const COLOR_GRAY_200 = "#EFEEED"
export const COLOR_GRAY_300 = "#DBD9D8"
export const COLOR_GRAY_400 = "#959494"
export const COLOR_GRAY_500 = "#5F5E5E"
export const COLOR_GRAY_600 = "#4B4A4B"
export const COLOR_GRAY_700 = "#2E2D2D"
export const COLOR_GRAY_800 = "#1C1C1C"
export const COLOR_GRAY_900 = "#141414"
export const COLOR_GRAY_900_RGBA = "rgba(20, 20, 20, 0.8)"
