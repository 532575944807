import {
  COLOR_BLUE,
  COLOR_CORAL,
  COLOR_DARK_CORAL,
  COLOR_ERROR,
  COLOR_GREEN,
  COLOR_STONE,
  COLOR_GRAY_200,
  COLOR_GRAY_300,
  COLOR_GRAY_400,
  COLOR_GRAY_700,
  COLOR_GRAY_900,
  COLOR_SUCCESS,
  COLOR_GRAY_800,
} from "../primitives"

export const COLOR_TEXT_PRIMARY = COLOR_GRAY_900
export const COLOR_TEXT_DISABLED = COLOR_GRAY_400
export const COLOR_TEXT_INVERTED = COLOR_STONE
export const COLOR_INTERACTIVE_LINK = COLOR_DARK_CORAL
export const COLOR_INTERACTIVE_ERROR = COLOR_ERROR
export const COLOR_INTERACTIVE_SUCCESS = COLOR_SUCCESS
export const COLOR_BACKGROUND_PRIMARY = COLOR_STONE
export const COLOR_BACKGROUND_SECONDARY_CORAL = COLOR_CORAL
export const COLOR_BACKGROUND_SECONDARY_BLUE = COLOR_BLUE
export const COLOR_BACKGROUND_SECONDARY_GREEN = COLOR_GREEN
export const COLOR_BACKGROUND_SECONDARY_GRAY = COLOR_GRAY_200
export const COLOR_BACKGROUND_INVERTED = COLOR_GRAY_900
export const COLOR_UI_BACKGROUND_PRIMARY = COLOR_DARK_CORAL
export const COLOR_UI_BACKGROUND_SECONDARY = COLOR_GRAY_900
export const COLOR_UI_BACKGROUND_INVERTED = COLOR_STONE
export const COLOR_UI_BACKGROUND_DISABLED = COLOR_GRAY_300
export const COLOR_UI_GRAPHICS = COLOR_GRAY_300
export const COLOR_LOGO_DEFAULT = COLOR_GRAY_900
export const COLOR_LOGO_INVERTED = COLOR_STONE
export const COLOR_BACKGROUND_FOOTER = COLOR_GRAY_700
export const COLOR_BACKGROUND_BANNER = COLOR_GRAY_800
