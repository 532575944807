import React, { createContext, memo, useContext, useMemo } from "react"

const localeContext = createContext(null)

export const LocaleProvider = memo(({ language, country, children }) => {
  return (
    <localeContext.Provider
      value={useMemo(() => ({ language, country }), [country, language])}
    >
      {children}
    </localeContext.Provider>
  )
})

export function useLocale() {
  const { language, country } = useContext(localeContext)
  const locale = useMemo(() => `${language}-${country.toUpperCase()}`, [
    country,
    language,
  ])
  return useMemo(
    () => ({
      language,
      country,
      locale,
      slugPrefix: locale.toLowerCase(),
    }),
    [country, language, locale]
  )
}
